.Videos
    margin-top: $fs-3

    .Videos-Buttons-Container
        display: flex
        justify-content: end

        .Videos-Buttons
            margin-left: $fs-5

            width: 35px
            height: 35px

            background: transparent
            border: 1px solid #d9d9d9
                radius: 8px

            &:hover
                color: #fff

                background-color: #545454

        .Videos-Buttons-Active
            color: #fff
            
            background-color: #545454

    .Video-Video
        display: block
        width: 100%
        height: 100%
        max-height: 55vh

        margin-top: $fs-5

        &::cue
            background-color: #ffffffec
            color: $pinkZtudia //#e61875
            font:
                family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
                size: 0.9em 
                weight: bold
                style: oblique
            text-shadow: 2px 2px 2px #ffdbeb

.DarkTheme
    .Videos
        .Videos-Buttons-Container
            .Videos-Buttons
                color: #fff


@media (min-width: 426px)
    .Videos
        .Video-Video
            width: 100%
            height: 100%
            max-height: 360px //420px

@media (min-width: 1736px)
    .Videos
        .Video-Video
            width: 88%
            height: 100%
            max-height: 760px //800px