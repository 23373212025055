.menuDown
    display: flex
    align-items: center
    justify-content: center

    position: fixed
    bottom: 0
    left: 0

    width: 100%
    height: 60px

    background-color: $fondoPrimario
    border:
        top: 1px solid $activeColor

    .List-menuDown
        display: flex
        align-items: center
        justify-content: center

        margin: auto 0

        .Item-menuDown
            display: inline-block

            margin:
                left: 12px

            &:first-child
                margin:
                    left: 0

            .Link-menuDown
                display: flex
                flex-direction: column
                justify-content: center

                width: 80px
                height: 46px

                text:
                    align: center
                    decoration: none
                font-size: $fs-5

                color: $inactiveColor
                border-radius: 6px

                &:hover
                    background-color: $activeColor
                
                .Icon-menuDown
                    display: block

                    font-size: $fs-4

        .Li-Btn-menuDown
            display: inline-block

            margin:
                left: 12px

            &:first-child
                margin:
                    left: 0

            .Btn-menuDown
                width: 40px
                height: 46px

                background-color: rgba($activeColor,0.3)
                color: $inactiveColor
                border: 0
                border-radius: 6px

                &:hover
                    background-color: $activeColor


//Tablet hacia arriba
@media ( min-width: 769px )
    .menuDown
        display: none