.Concepts
    &__Title
        font-size: $fs-2
        text-align: center

    .Concepts-Content
        img
            display: block
            margin: auto
            
            width: 100%
            max-width: 800px

            padding: $fs-4 0

        & > h1
            font-size: $fs-3

        ol
            & > li
                margin-bottom: $fs-4

    .Concepts-Modal-Background
        position: fixed
        top: 50%
        left: 50%
        transform: translateX(-50%) translateY(-50%)

        width: 100%
        height: 100%

        background-color: rgba($inactiveColor,0.95)
    
        .Concepts-Modal-ButtonClose
            position: fixed
            top: $fs-4
            right: $fs-4

            display: flex
            justify-content: center
            align-items: center

            width: 50px
            height: 50px

            color: #fff
            font-size: $fs-3

            border: 0
                radius: 8px
            background-color: red

            &:hover
                background-color: rgba(red, 0.8)

    .Concepts-Modal
        position: fixed
        top: 50%
        left: 50%
        transform: translateX(-50%) translateY(-50%)

        width: 100%
        max-width: 800px

        .carousel-indicators [data-bs-target]
            background-color: $activityColor

        .carousel-control-prev-icon, .carousel-control-next-icon
            color: $activityColor
            font-size: $fs-2
            

// @media (min-width: 768px)
//     .Concepts
//         h1
//             font-size: $fs-3

.DarkTheme
    .Concepts
        .Concepts-Modal-Background
            background-color: rgba($inactiveColor,0.95)