@mixin HeaderMenuOptionsSize($fontSize)
    display: flex
    justify-content: center
    align-items: center

    margin-left: $fs-5

    width: 34px
    height: 34px
    border: 1px solid #000
        radius: 6px

    font-size: $fontSize

    cursor: pointer

    &:hover
        background-color: $activeColor



.Header
    display: block
    margin-top: $fs-5

    .Header-Side1
        .Header-Brand
            display: flex
            align-items: center
            justify-content: center

            .Header-Image
                width: 100%
                max-width: 66px

    .Header-Side2
        .Header-Text
            margin-bottom: 0.5em

            text-align: center

    .Header-Side3
        .Header-Menu
            width: 100%

            .Header-Menu-Button
                display: block
                width: 100%

                background: transparent
                border: 0

            .Header-Menu-Options
                .Header-Menu-Options-Size
                    display: flex

                    .Header-Menu-Options-Size-Small
                        @include HeaderMenuOptionsSize($fs-5)
                        margin-left: 0

                    .Header-Menu-Options-Size-Medium
                        @include HeaderMenuOptionsSize($fs-4)

                    .Header-Menu-Options-Size-Big
                        @include HeaderMenuOptionsSize($fs-3)



@media (min-width: 480px)
    .Header
        display: flex
        justify-content: space-between
        align-items: center

        .Header-Side1
            width: 150px
            
            .Header-Brand
                justify-content: start
        
        .Header-Side2
            .Header-Text
                margin-bottom: 0

        .Header-Side3
            width: 150px

            .Header-Menu
                display: flex
                justify-content: end

                width: auto

                .Header-Menu-Button
                    width: auto



.DarkTheme
    .Header
        .Header-Side3
            .Header-Menu
                .Header-Menu-Button
                    color: #fff

                    background-color: $fondoPrimarioDT