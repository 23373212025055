.Download
    margin-top: $fs-2

    &__Image
        display: block
        margin: auto

        width: 100%
        max-width: 250px

    &__Title
        font:
            size: $fs-4
            weight: 400
        text-align: center

    &__Link
        display: block
        margin:
            top: $fs-3
            left: auto
            right: auto
        padding: 12px 0
        
        width: 100%
        max-width: 350px

        color: white
        text-align: center

        border: 0
            radius: 8px
        background-color: $pinkZtudia

        text-decoration: none

        &:hover
            background-color: rgba($pinkZtudia,0.9)