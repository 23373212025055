.Login
    margin: auto

    .Login-Image
        width: 100%
        max-width: 350px

    .loginForm
        margin: auto
        
        width: 100%
        max-width: 500px
        
        background-color: $fondoPrimario

        .loginForm-Title
            font-size: $fs-3
            text-align: center

        .loginForm-Checkbox
            margin-top: $fs-5

            .loginForm-Check
                display: inline-block

            .loginForm-Label
                display: inline-block
                margin-left: 4px
                
        .loginForm-Input-Container
            position: relative // Para posicionar el ícono dentro del contenedor

            .loginForm-Input
                display: block
                margin-top: $fs-5
                width: 100%
                padding:
                    top: $fs-5
                    bottom: $fs-5
                    left: $fs-5
                border: 1px solid $activeColor
                    radius: 8px
            
            /* Estilos para input con error */
            .loginForm-Input-Error
                border-color: red

            .loginForm-Input-Error-Message
                color: red
                font-size: small
                margin-top: 5px

            /* Estilos para input con éxito */
            .loginForm-Input-Success
                border-color: green

            .loginForm-Input-Icon-Container
                position: absolute
                right: 10px
                top: 50%
                transform: translateY(-50%)
                cursor: pointer

        .loginForm-Error-Message
            color: red
            text-align: center

        .loginForm-Button
            //@include DegradedExcel
            margin-top: $fs-3
            padding: 12px 0
            
            width: 100%

            color: white

            border: 0
                radius: 8px
            background-color: $pinkZtudia

            &:hover
                background-color: rgba($pinkZtudia,0.9)

        .loginForm-Button-Disabled
            color: #545454

            background-color: #d9d9d9

            &:hover
                background-color: rgba(#d9d9d9,0.9)

    &__Download
        margin-top: $fs-3

        &__Link
            display: block
            
            margin: 0 auto

            text-align: center

            cursor: pointer
            
@media ( min-width: 576px )
    .Login
        .loginForm
            padding: $fs-4

            border: 1px solid $activeColor
                radius: 8px

.DarkTheme
    .Login
        .loginForm
            background-color: $fondoPrimarioDT