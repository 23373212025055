$tilesMWidthV2: 280px
$tilesMWidth: 370px
$tilesSMWidth: 210px

$tilesMTextWidth: 280px
$tilesSMTextWidth: 128px

//$ButtonColor: #f29100
$ButtonColor: $pinkZtudia
//$BackgroundColor: #ffecd1
$BackgroundColor: #ffcfe3


//--- Commons ---//
@mixin BaseButton($textColor, $backgroundColor, $borderColor, $textColorHover, $backgroundColorHover, $borderColorHover)
    margin-top: $fs-5

    width: 100%
    height: $fs-2

    color: $textColor
    font-size: $fs-5
    
    background-color: $backgroundColor
    border: 1px solid $borderColor
        radius: 8px

    &:hover
        color: $textColorHover
        background-color: $backgroundColorHover
        border-color: $borderColorHover

@mixin Tiles()
    display: flex
    justify-content: space-between
    flex-direction: column

    width: tilesMWidthV2

    padding: $fs-4
    margin-left: 4px
    
    border: 1px solid #d9d9d9
        radius: 8px

    color: #545454

    cursor: pointer
    text-decoration: none

    &:hover
        color: #000

        background-color: #d9d9d9

    .Tile-Info
        display: flex
        align-items: center

        .Tile-Figure
            width: 50px
            height: 50px

            margin-bottom: 0

            font-size: $fs-3

            .Tile-Icon
                display: flex
                justify-content: center
                align-items: center

                height: 100%
//--- Commons ---//



.Tiles
    display: flex

    padding-bottom: 6px

    .Tiles-ApprovedCourses
        @include Tiles

        .Tile-Info
            .Tile-ContainerText
                display: flex
                flex-direction: column

                justify-content: center

                padding-left: 8px
                width: $tilesMTextWidth
                height: 60px

                .Tile-Text
                    display: block

                    font-size: $fs-4

                    white-space: nowrap
                    text-overflow: ellipsis
                    overflow: hidden

                    &:first-child
                        font-size: $fs-5

                    &:nth-child(odd)
                        font-size: $fs-5

    .Tiles-Courses
        @include Tiles

        .Tile-Info
            .Tile-ContainerText
                display: block

                padding-left: 8px
                width: $tilesMTextWidth
                max-height: 60px

                .Tile-Text
                    display: block

                    max-height: 50px

                    font-size: $fs-4

                    overflow: hidden

                    &:nth-child(1)
                        height: auto

                        font-size: $fs-5

        .Tile-Button-Highlight
            @include BaseButton(#000, transparent, $ButtonColor, #fff, $ButtonColor, $ButtonColor)
            
            margin-top: $fs-4

        .Tile-Button-Transparent
            @include BaseButton(#000, transparent, transparent, #000, transparent, transparent)

            height: $fs-3

    .ActiveTile
        background-color: $BackgroundColor
        border: 2px solid $ButtonColor

        &:hover
            background-color: $BackgroundColor

        .Tile-Button-Highlight
            @include BaseButton(#fff, $ButtonColor, $ButtonColor, #fff, rgba($ButtonColor,0.7), rgba($ButtonColor,0.7))

            margin-top: $fs-4
    
    .Tiles-ButtonMoveTile
        padding: 0

        font-size: $fs-4

        background-color: transparent
        border: 0
            radius: 8px
            
        &:hover
            background-color: $activeColor


.DarkTheme
    .Tiles
        .Tiles-ApprovedCourses, .Tiles-Courses
            border-color: #545454
            
            color: #d9d9d9

            &:hover
                color: #fff

                background-color: #545454

            .Tile-Button-Highlight
                @include BaseButton($ButtonColor, transparent, $ButtonColor, #fff, $ButtonColor, $ButtonColor)
                
                margin-top: $fs-4

            .Tile-Button-Transparent
                @include BaseButton(#fff, transparent, transparent, #fff, transparent, transparent)

                height: $fs-3
        
        .ActiveTile
            background-color: $BackgroundColor
            border: 2px solid $ButtonColor

            color: #000

            &:hover
                color: #000
                
                background-color: $BackgroundColor
    
            .Tile-Button-Highlight
                @include BaseButton(#fff, $ButtonColor, $ButtonColor, #fff, rgba($ButtonColor,0.8), rgba($ButtonColor,0.8))
                
                margin-top: $fs-4

            .Tile-Button-Transparent
                @include BaseButton(#000, transparent, transparent, #000, transparent, transparent)

                height: $fs-3


.HideMoveButton > .Tiles-ButtonMoveTile
    visibility: hidden

.ShowMoveButton > .Tiles-ButtonMoveTile
    visibility: visible