//Tamaños (fuente, espaciados, altos, etc.)
$fs-1: 67.8px
$fs-2: 41.9px
$fs-3: 25.9px
$fs-4: 16px
$fs-5: 9.9px
$fs-6: 6.1px


//Colores Ztudia
$blackZtudia: #000 //rgba(0,0,0,1)
$pinkZtudia: #e61b72 //rgba(230,27,114,1)
$blueZtudia: #35a8e0 //rgba(53,168,224,1)
$redZtudia: #e20613 //rgba(226,6,19)
$orangeZtudia: #f29100 //rgba(242,145,0)
$greenZtudia: #8abd24 //rgba(138,189,36)
$purpleZtudia: #755ea6 //rgba(117,94,166)


//Colores secundarios
//$elementSecondary: #545454
//$elementSecondary: #d9d9d9




//Colores
$linkColor: #000000 //#0a58ca
$ratingColor: #ffbf00 //255,191,0
$courseCompleted: forestgreen
$activityColor: #2640b6

// $FondoGris: #d9d9d9 //rgba(0, 0, 0, 0.1)
// $FondoGrisClaro: rgba(#d9d9d9, 0.1) //rgba(0, 0, 0, 0.1)

//Tema claro
$inactiveColor: #545454
$activeColor: #d9d9d9
$fondoPrimario: #fff

//Tema oscuro
$inactiveColorDT: #fff
$activeColorDT: rgba(255,255,255,0.5)
$fondoPrimarioDT: #181818


//General
.BodyComplete
    display: flex
    flex-direction: column
    min-height: 100vh

.Container-FullHeight
    flex: 1 0 auto

.Container-FlexContent
    display: flex


.Container
    margin:
        left: auto
        right: auto

    width: 100%

@media (min-width: 576px)
    .Container
        max-width: 540px

@media (min-width: 768px)
    .Container
        max-width: 720px

@media (min-width: 992px)
    .Container
        max-width: 960px

@media (min-width: 1200px)
    .Container
        max-width: 1140px

.Button-Return
    border: 0
    background-color: transparent

    text-align: left

    font-size: $fs-2

    color: $pinkZtudia

// .Button-Success
//     @include BaseButton(#179025, #fff)

// .Dashboard-BtnSecondary
//     @include BaseButton(#2640b6, #fff)

// .Dashboard-BtnInactive
//     @include BaseButton(#d9d9d9, #000)

.DarkTheme
    background-color: $fondoPrimarioDT
    color: #fff

    .Button-Return
        color: $inactiveColorDT



    

//Material didáctico
.Excel
    color: forestgreen


//Fondos degradados
//Se colocan aquí por si se utilizan dentro de otros archivos SASS
@mixin DegradedExcel()
    color: #fff!important

    background: rgb(0,84,44)
    background: -moz-linear-gradient(90deg, rgba(0,84,44,1) 0%, rgba(2,143,75,1) 50%, rgba(2,143,75,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(0,84,44,1) 0%, rgba(2,143,75,1) 50%, rgba(2,143,75,1) 100%)
    background: linear-gradient(90deg, rgba(0,84,44,1) 0%, rgba(2,143,75,1) 50%, rgba(2,143,75,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00542c",endColorstr="#028f4b",GradientType=1)
    
    //background: rgb(0,84,44)
    //background: linear-gradient(90deg, rgba(0,84,44,1) 0%, rgba(2,143,75,1) 50%, rgba(2,143,75,1) 100%)

@mixin DegradedWord()
    color: #fff!important

    background: rgb(1,82,161)
    background: -moz-linear-gradient(90deg, rgba(1,82,161,1) 0%, rgba(0,115,225,1) 50%, rgba(0,115,225,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(1,82,161,1) 0%, rgba(0,115,225,1) 50%, rgba(0,115,225,1) 100%)
    background: linear-gradient(90deg, rgba(1,82,161,1) 0%, rgba(0,115,225,1) 50%, rgba(0,115,225,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0152a1",endColorstr="#0073e1",GradientType=1)

.ActiveRating
    color: $ratingColor

.InactiveRating
    &:hover
        color: $ratingColor



//Clases de fondos degradados
//Se colocan aquí para que se apliquen los estilos por encima de los establecidos de manera predeterminada en los Tiles u otros elementos
.DegradedExcel
    @include DegradedExcel

.DegradedWord
    @include DegradedWord



@import './progressBar'
@import './searchInput'
@import './message'
@import './loading'
@import './goToTop'