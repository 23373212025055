$SearchInputBorderRadius: 8px


.SearchInput
    display: block
    width: 100%

    border: 1px solid $inactiveColor
        radius: $SearchInputBorderRadius
    
    padding: $fs-5

.SearchResults
    display: none

    padding-left: 0
    
    border: 1px solid $inactiveColor
        top: transparent
        top-left-radius: 0
        top-right-radius: 0
        bottom-left-radius: $SearchInputBorderRadius
        bottom-right-radius: $SearchInputBorderRadius
    
    list-style: none

    .SearchResults-Title
        padding: $fs-3 $fs-5
            left: $fs-4
        margin-bottom: 0
        
    .SearchResults-Item
        display: flex
        justify-content: space-between

        padding: $fs-4

        cursor: pointer
        
        &:nth-child(even)
            background-color: rgba($activeColor, 0.5)

        &:hover
            color: #fff
            background-color: $inactiveColor

        .SearchResults-Item-Container
            .SearchResults-Text
                margin-left: 4px

            .SearchResults-Type
                font:
                    size: $fs-5
                    style: italic
                margin-left: 4px

.ShowSearchResults
    display: block


.DarkTheme
    .SearchInput
        color: white
        background-color: transparent //$activeColor
        border: 1px solid $inactiveColor

    .SearchResults
        .SearchResults-Item
            &:nth-child(even)
                background-color: rgba($inactiveColor, 0.2)

            &:hover
                color: #000
                background-color: $activeColor