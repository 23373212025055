.Exams
    margin-top: $fs-2
    margin-bottom: $fs-2

    .Exams-Container
        margin: 0 auto
            top: $fs-3

        width: 96%

        &__Evaluation
            display: none

            .Exams-Container-Data
                width: 96%
                max-width: 550px
                margin: auto

                padding: $fs-3 0

                border: 1px solid #d9d9d9
                    radius: 8px

                .Exams-Container-Icon
                    display: block
            
                    font-size: $fs-2
                    text-align: center

                .Exams-Container-Title
                    text-align: center
                    font-size: $fs-3

                .Exams-Container-Text
                    text-align: center

            .Exams-Container-Instructions
                width: 96%
                max-width: 550px
                margin:
                    top: $fs-2
                    left: auto
                    right: auto
                    //bottom: $fs-3

                //padding: $fs-3 0

                //border: 1px solid #d9d9d9
                    //radius: 8px
            
            .Exams-Container-Instructions-Subcontainer
                width: 100%
                max-width: 480px
                margin: auto

            .Exams-Container-Instructions-Top
                //margin-top: $fs-2
            
                font:
                    weight: 600
                    //size: $fs-3
                text-align: center

            .Exams-Container-Instructions-Highlight
                font-weight: 600

            .Exams-Container-Buttons
                display: block
                padding: $fs-5 0
                margin: $fs-5 auto

                width: 100%
                max-width: 350px

                color: #fff

                border: 0
                    radius: 8px
                background-color: $pinkZtudia

                &:hover
                    background-color: rgba($pinkZtudia, 0.85)

            .Exams-Container-Buttons-Disabled
                color: #545454

                background-color: #d9d9d9

                &:hover
                    background-color: rgba(#d9d9d9,0.9)

            .Exams-Container-Notes
                margin-bottom: 0

                //font-size: $fs-5
                color: red
                text-align: center
            
        &__Message
            .Exams-Container-Instructions
                width: 96%
                max-width: 550px
                margin:
                    top: $fs-2
                    left: auto
                    right: auto
                    //bottom: $fs-3

                //padding: $fs-3 0

                //border: 1px solid #d9d9d9
                    //radius: 8px
            
            .Exams-Container-Instructions-Subcontainer
                width: 100%
                max-width: 480px
                margin: auto

            .Exams-Container-Instructions-Top
                //margin-top: $fs-2
            
                font:
                    weight: 600
                    //size: $fs-3
                text-align: center

            .Exams-Container-Instructions-Highlight
                font-weight: 600

@media ( min-width: 768px )
    .Exams
        .Exams-Container
            &__Evaluation
                display: block

            &__Message
                display: none