.Loading
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%

    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    color: #fff

    background-color: $inactiveColor