.Challenges
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    transition: background-color 0.5s, padding 0.5s

    .Challenges-Text
        font-size: $fs-4
        font-weight: 600
        transition: font-size 0.5s

    .Challenges-Options
        display: flex
        flex-wrap: wrap
        //flex-direction: column
        justify-content: center
        align-items: center

        .Challenges-Option
            display: block
            margin: $fs-4 auto

            width: 90%
            max-width: 360px //500px

            border: 2px solid $activeColor
                radius: 16px
            transition: border 0.5s

            cursor: pointer

            &:hover
                background-color: $activeColor

        .Challenges-Option-Unselected
            filter: grayscale(50%) // Aplicar un filtro de escala de grises
            opacity: 0.5 // Reducir la opacidad

        .Challenges-Option-Good
            border-color: green // Borde verde para la opción correcta

        .Challenges-Option-Bad
            border-color: red // Borde rojo para la opción incorrecta

    .Challenges-Button-Reset
        margin-top: $fs-4

        height: $fs-2
        width: 100%
        max-width: 250px

        color: #000
        background-color: #d9d9d9
        border: 1px solid #d9d9d9
            radius: 8px
        
        &:hover
            background-color: rgba(#d9d9d9, 0.85)


.Challenges-Good
    padding: $fs-1 0
    background-color: rgba(forestgreen, 0.4)

    .Challenges-Text
        font-size: $fs-3

    .Challenges-Options
        .Challenges-Option
            background-color: rgba(forestgreen, 0.4)

            &:hover
                background-color: rgba(forestgreen, 0.4)

.Challenges-Bad
    padding: $fs-1 0
    background-color: rgba(red, 0.4)

    .Challenges-Text
        font-size: $fs-3

    .Challenges-Options
        .Challenges-Option
            background-color: rgba(red, 0.4)

            &:hover
                background-color: rgba(red, 0.4)


@media (min-width: 768px)
    .Challenges
        .Challenges-Options
            //flex-direction: row

            .Challenges-Option
                margin: $fs-4