.Menu
    position: fixed
    top: 0
    left: 0

    width: 180px
    height: 100%

    background-color: $fondoPrimario
    border:
        right: 1px solid $activeColor

    transition: width 0.2s

    .Menu-container
        display: flex
        flex-direction: column
        justify-content: space-between

        padding: 20px 0

        .Menu-button
            display: block
            margin:
                left: auto
                right: auto

            width: 40px
            height: 40px

            font-size: $fs-3

            background-color: rgba($activeColor,0.3)
            color: $inactiveColor
            border: 0
                radius: 6px

            &:hover
                background-color: $activeColor

        .Menu-items
            margin-top: 80px

            .Menu-link
                display: block
                margin: 4px 0
                padding: 6px 6px

                border-radius: 8px

                color: $inactiveColor

                text-decoration: none

                &:hover
                    background-color: $activeColor
                
                @mixin MenuLink
                    display: inline

                    font-size: $fs-4
                    text-align: left

                    transition: display font-size text-align 0.5s
                
                .Menu-link-icon
                    @include MenuLink()

                .Menu-link-text
                    @include MenuLink()


.sMenu
    width: 90px

    transition: width 0.2s
    
    .Menu-container
        .Menu-items
            .Menu-link
                .Menu-link-icon
                    display: block
                    padding-right: 4px

                    font-size: $fs-3
                    text-align: center

                    transition: display font-size text-align 0.5s

                .Menu-link-text
                    display: block

                    font-size: $fs-5
                    text-align: center

                    transition: display font-size text-align 0.5s


//Celular hacia abajo
@media ( max-width: 768px )
    .Menu
        display: none