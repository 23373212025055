.Quizzes
    margin-top: $fs-3

    .Quiz-Question-Question-ResultsSent
        text-align: center
        //font-size: $fs-3

        .Quiz-Question-Question-ResultsSent-Icon
            font-size: $fs-1
            color: forestgreen

    .Quiz-Question-Question
        display: flex
        flex-direction: column

        width: 100%
        max-width: 800px

        border: 1px solid $inactiveColor
            radius: 8px

        margin: $fs-3 auto
        padding: $fs-4

        .Quiz-Question-Text
            font-weight: 600
            
        .Quiz-Question-Option-Label
            margin: 4px 0

            .Quiz-Question-Option-Radio
                margin-right: 4px

        @mixin Result($bgColor)
            margin-top: $fs-4
            padding: $fs-4

            background-color: rgba($bgColor,0.2)
            border: 1px solid $bgColor
                radius: 8px

        .Quiz-Question-Result-Good
            @include Result(forestgreen)

        .Quiz-Question-Result-Bad
            @include Result(red)

    .Quiz-Question-CorrectAnswers
        text-align: center

    .Quiz-Question-Buttons
        display: flex
        flex-direction: column

        @mixin BaseButton($backgroundColor, $textColor)
            display: block
            padding: $fs-5 0
            margin: $fs-5 auto

            width: 100%
            max-width: 300px

            color: $textColor
            background-color: $backgroundColor
            border: 0
                radius: 8px

            &:hover
                background-color: rgba($backgroundColor,0.8)

        .Quiz-Question-Buttons-Retry
            @include BaseButton($activeColor, $inactiveColor)

        .Quiz-Question-Buttons-Record
            @include BaseButton(forestgreen, #fff)
            
        .Quiz-Question-Buttons-Validate
            @include BaseButton(forestgreen, #fff)

.DarkTheme
    .Quizzes
        .Quiz-Question-Question
            border-color: $activeColor


@media (min-width: 768px)
    .Quizzes
        .Quiz-Question-Buttons
            flex-direction: row
            justify-content: center

            .Quiz-Question-Buttons-Retry
                margin:
                    left: $fs-5
                    right: $fs-5

            .Quiz-Question-Buttons-Record
                margin:
                    left: $fs-5
                    right: $fs-5
                
            .Quiz-Question-Buttons-Validate
                margin:
                    left: $fs-5
                    right: $fs-5