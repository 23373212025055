.Results
    margin: auto
        top: $fs-2
        bottom: $fs-1

    width: 96%

    .Results-Title
        margin:
            top: $fs-3
            bottom: $fs-5

        font:
            size: $fs-3
            weight: 600

    .Results-Info
        margin-bottom: $fs-5

    .Last-Child
        padding-bottom: $fs-3

    /*.Results-Course
        margin-bottom: $fs-5*/

    /*.Results-Score
        margin-bottom: $fs-5*/

    /*.Results-TotalQuestions
        margin-bottom: $fs-3*/

    .Results-Question
        margin: $fs-4 0
        padding: $fs-4

        width: 100%
        max-width: 600px

        border: 2px solid $inactiveColor
            radius: 8px

        .Results-Question-Detail-Good
            color: forestgreen
            
        .Results-Question-Detail-Bad
            color: red
    
    @mixin ResultsQuestion($color)
        border-color: $color

        cursor: pointer

        &:hover
            background-color: rgba($color, 0.1)

    .Results-Question-Good
        @include ResultsQuestion(forestgreen)

    .Results-Question-Bad
        @include ResultsQuestion(red)


//@import './questionDrafting'