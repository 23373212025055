@mixin BaseButton($width)
    display: block
    padding: $fs-5 0
    margin: 0 $fs-5

    width: $width

    font-weight: 600


.Teaching-Menu
    position: fixed
    left: 0
    bottom: 0
    display: flex
    justify-content: start
    //align-items: center
    flex-shrink: 0

    margin-top: $fs-2
    
    width: 100%
    padding: $fs-5 0

    border-top: 1px solid $inactiveColor
    background-color: $fondoPrimario

    overflow: auto

    &::-webkit-scrollbar
        height: 6px

    &::-webkit-scrollbar-thumb
        background-color: #000
        border-radius: 4px

    .Teaching-Menu-Container
        display: flex
        justify-content: center
        margin: auto

        width: 680px

        .Teaching-Menu-Button
            @include BaseButton(60px)

            color: $inactiveColor
            background-color: transparent
            border: 1px solid $inactiveColor
                radius: 8px

            &:hover
                color: #fff
                background-color: rgba($inactiveColor,0.8)

            .Teaching-Menu-Button-Text
                display: none
                //display: block
                //font-size: $fs-5

        .Teaching-Menu-Bottom-Active
            @include BaseButton(60px)

            color: #fff
            background-color: $pinkZtudia
            border: 1px solid $pinkZtudia
                radius: 8px

            &:hover
                color: #fff
                background-color: $pinkZtudia

        .Teaching-Menu-Button-Disabled
            @include BaseButton(60px)

            color: $inactiveColor
            text-align: center
            background-color: $activeColor
            border: 1px solid $activeColor
                radius: 8px

            cursor: not-allowed

            .Teaching-Menu-Button-Disabled-Text
                display: none

@media (min-width: 1030px)
    .Teaching-Menu
        left: 50%
        bottom: 20px
        transform: translateX(-50%)

        justify-content: center
        padding: $fs-4 0

        max-width: 1000px

        border:
            left: 1px solid $inactiveColor
            right: 1px solid $inactiveColor
            bottom: 1px solid $inactiveColor
            radius: 8px

        .Teaching-Menu-Container
            width: 980px

            .Teaching-Menu-Button
                @include BaseButton(150px)

                .Teaching-Menu-Button-Text
                    display: inline-block
                    font-size: $fs-4
                    margin-left: $fs-5

            .Teaching-Menu-Bottom-Active
                @include BaseButton(150px)

            .Teaching-Menu-Button-Disabled
                @include BaseButton(150px)

                color: $inactiveColor
                background-color: $activeColor
                border: 1px solid $activeColor
                    radius: 8px

                .Teaching-Menu-Button-Disabled-Text
                    display: inline-block
                    font-size: $fs-4
                    margin-left: $fs-5

@media (min-width: 1736px)
    .Teaching-Menu
        position: fixed
        left: auto
        top: 50%
        right: $fs-4
        transform: translateX(0) translateY(-50%)

        margin-top: 0

        width: 220px
        padding: $fs-2 $fs-4

        border: 1px solid $inactiveColor
            radius: 8px
        
        .Teaching-Menu-Container
            flex-direction: column
            align-items: center

            .Teaching-Menu-Button
                margin-top: $fs-4

                &:first-child
                    margin-top: 0


.DarkTheme
    .Teaching-Menu
        background-color: $fondoPrimarioDT

        .Teaching-Menu-Container
            .Teaching-Menu-Button
                color: $activeColor
                border-color: $activeColor

                &:hover
                    color: $inactiveColor
                    background-color: $activeColor

            .Teaching-Menu-Bottom-Active
                color: #fff
                border-color: $pinkZtudia
                
                &:hover
                    color: #fff
                    background-color: $pinkZtudia

            .Teaching-Menu-Button-Disabled
                color: $activeColor
                border-color: $inactiveColor
                background-color: $inactiveColor