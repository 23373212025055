.Teaching
    margin-top: $fs-2

    padding-bottom: $fs-1

    .Teaching-Container
        margin: 0 auto
        margin-top: $fs-3

        padding-bottom: $fs-3

        width: 96%


@media (min-width: 1400px)
    .Teaching
        padding-bottom: $fs-1
        

@import './Concepts/concepts'
@import './Videos/videos'
@import './Quizzes/quizzes'
@import './Challenges/challenges'