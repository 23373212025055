//$ButtonColor: #f29100
$ButtonColor: $pinkZtudia


.Course
    margin-top: $fs-2
    padding-bottom: $fs-1

    .Course-Info
        margin-top: $fs-4
        text-align: center

        .Course-Text
            display: flex
            flex-direction: column
            align-items: center
            
            .Course-Name
                font-size: $fs-3
                line-height: $fs-4

            .Course-Type
                font-style: italic

            .Course-Objective-Button
                border: 0
                background: transparent

                font-size: $fs-5

                text-decoration: underline

            .Course-Objective
                margin-top: $fs-5

                padding: 0
                height: 0

                overflow: hidden

                //.Course-Objective-Title
                    //font-size: $fs-3

                .Course-Objective-Text
                    text-align: left

            .Course-Objective-Show
                padding: $fs-3
                height: auto

                border: 1px solid #d9d9d9
                    radius: 8px

    .Course-Button
        width: 280px
        height: $fs-2 //50px

        padding: 0 $fs-4

        color: #fff
        font-weight: 600

        background-color:  $ButtonColor
        border: 0
            radius: 8px

        &:hover
            background-color: rgba($ButtonColor,0.85)

        .Course-Button-Text
            display: block

            text-align: left

            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

        .Course-Button-Icon
            display: block
            
            font-size: $fs-5

            text-align: right

    .Course-Title
        margin-top: $fs-3

        font-size: $fs-4

    .Course-Topics
        margin-top: $fs-2

@media (min-width: 768px)
    .Course
        .Course-Info
            .Course-Text
                align-items: end

        .Course-Title
            font-size: $fs-3

.DarkTheme
    .Course
        .Course-Info
            .Course-Text
                .Course-Objective-Button
                    color: #fff
                    
                    &:hover
                        color: rgba(#fff,0.8)

@import './Blocks/blocks'