.TileList
    height: auto //113.47px
    overflow-x: auto
    transition: height 0.3s

    margin-top: $fs-3

    &::-webkit-scrollbar
        height: 8px

    &::-webkit-scrollbar-thumb
        background-color: $inactiveColor
        border-radius: 4px

.ShowTileList
    display: flex

.HideTileList
    height: 0

    overflow-y: hidden