.Section
    .Section-Button
        font-size: $fs-4

        border: 0
        background: transparent
        
    .Section-Button-Dismiss
        background: transparent
        border: 0

        color: $linkColor
        font-size: $fs-5

        text-decoration: underline

    .hideSection
        transform: rotate(270deg)

.Section-Separation
    margin-top: $fs-3



.DarkTheme
    .Section
        .Section-Button
            color: $inactiveColorDT

        .Section-Button-Dismiss
            color: $inactiveColorDT


@media (min-width: 768px)
    .Section
        .Section-Button
            font-size: $fs-3


//.Section-QuickAccess

//.Section-Favorites

//.Section-Courses