.Activity
    display: flex
    flex-direction: column

    width: 96%
    margin: 0 auto
        top: $fs-3

    padding-bottom: $fs-1

    .Activity-Instructions
        .Activity-Instructions-Title
            font:
                size: $fs-3
                weight: 600
            color: $pinkZtudia

    .Activity-Files
        margin-top: $fs-3

        .Activity-Files-Title
            font:
                size: $fs-3
                weight: 600
            color: $pinkZtudia

        .Activity-Files-Download
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center

            padding: $fs-5 0
            border: 1px solid $pinkZtudia
                radius: 8px

            font-size: $fs-3
            color: $pinkZtudia
            
            cursor: pointer
            text-decoration: none

            &:hover
                //@include DegradedExcel
                color: #fff
                background-color: $pinkZtudia
                
            .Activity-Files-Download-Image
                font-size: $fs-2


@media (min-width: 1024px)
    .Activity
        flex-direction: row

        .Activity-Instructions
            width: 600px

            padding: $fs-3

            border:
                right: 1px solid $pinkZtudia
                left: 1px solid $pinkZtudia

        .Activity-Files
            margin: 0 auto
            margin-top: 0

            .Activity-Files-Title
                text-align: center
                font:
                    size: $fs-3
                weight: 600
                color: $pinkZtudia

            .Activity-Files-Download
                padding: $fs-5 $fs-2


.DarkTheme
    .Activity
        .Activity-Files
            .Activity-Files-Download
                border-color: $activeColor
                color: $activeColor