$BlockBorderRadius: 8px
//$ButtonColor: #f29100
$ButtonColor: $pinkZtudia

.Blocks
    margin-top: $fs-5

    .Block
        display: flex
        justify-content: space-between

        border: 1px solid #545454
            radius: $BlockBorderRadius

        cursor: pointer

        &:hover
            color: #fff
            border-color: $ButtonColor
            background-color: $ButtonColor

        .Block-Info
            padding: $fs-4
            width: 100%

            .Block-Title
                margin-bottom: $fs-5
                
        .Block-Buttons
            display: flex
            align-items: center

            /*.Block-Buttons-Exam
                width: 150px
                height: 40px

                color: #545454
                background-color: transparent
                border: 1px solid #545454
                    radius: $BlockBorderRadius

                &:hover
                    color: #fff
                    background-color: $orangeZtudia
                    border-color: $orangeZtudia*/

            .Block-Buttons-List
                display: block
                padding: 0 $fs-4
                margin-left: $fs-3

                font-size: $fs-3
                height: 100%

                background: transparent
                border: 0

                &:hover
                    background-color: rgba(#fff,0.2)

    /*.Block-Info-Active
        background-color: $ButtonColor

        .Block-Buttons
            .Block-Buttons-List
                color: #fff*/


    .Topics
        height: 0
        padding: 0

        overflow: hidden

        animation: height 1s
        animation: padding 1s

        .Topics-Buttons-Objective
            display: block

            width: 100%
            padding: $fs-5 0

            font-size: $fs-5
            
            background: rgba($ButtonColor, 0.5)
            border: 0

            &:hover
                background: rgba($ButtonColor, 0.4)

        .Topics-Objective
            height: 0
            padding: 0 $fs-4

            overflow: hidden

            animation: height padding 1s

            .Topics-Objective-Close
                display: block

                margin-left: auto
                margin-bottom: $fs-5

                border: 1px solid red
                    radius: $BlockBorderRadius
                color: red
                
                background: transparent

                &:hover
                    color: #fff
                    background-color: red

        .Topics-Item
            display: flex
            justify-content: space-between

            padding: $fs-4
            
            list-style: none

            cursor: pointer

            &:first-child
                margin: 0

            &:nth-child(even)
                background-color: rgba(#d9d9d9, 0.5)

            &:hover
                color: #000
                background-color: #d9d9d9

        .Topics-Activity
            display: flex
            justify-content: space-between

            padding: $fs-4
            
            color: #fff
            background-color: #2640b6
            list-style: none
            cursor: pointer

            &:hover
                background-color: rgba(#2640b6,0.8)

        .Topics-Exam
            display: flex
            justify-content: space-between

            padding: $fs-4
            
            color: #fff
            background-color: #755ea6
            list-style: none
            cursor: pointer

            &:hover
                background-color: rgba(#755ea6,0.8)



.DarkTheme
    .Blocks
        .Block
            &:hover
                color: #fff
                
                border-color: $ButtonColor
                    
                background-color: $ButtonColor

            .Block-Buttons
                .Block-Buttons-List
                    color: #fff

                .Block-Buttons-Exam
                    color: white
                    background-color: transparent
                    border-color: white

                    &:hover
                        color: #fff
                        background-color: $orangeZtudia
                        border-color: $orangeZtudia

        .Topics
            .Topics-Buttons-Objective
                color: #fff

                background: rgba($ButtonColor, 0.7)
                
                &:hover
                    background: rgba($ButtonColor, 0.6)

            .Topics-Objective-Show
                height: auto

                padding:
                    top: $fs-4
                    bottom: $fs-4

                border-bottom: 1px solid #545454

            .Topics-Item
                &:nth-child(even)
                    background-color: rgba(#545454, 0.2)

                &:hover
                    color: #000
                    background-color: $activeColor

.Blocks-Show
    .Block
        color: #fff
        font-weight: 600
        
        border:
            color: $ButtonColor
            bottom:
                left-radius: 0 
                right-radius: 0
            
        background-color: $ButtonColor

        .Block-Buttons
            .Block-Buttons-List
                color: #fff

    .Topics
        .Topics-Objective-Show
            height: auto

            padding:
                top: $fs-4
                bottom: $fs-4

            border-bottom: 1px solid #d9d9d9

    .Topics-Show
        height: auto

        border: 1px solid #545454
        border-top: transparent
        border-bottom:
            left-radius: $BlockBorderRadius
            right-radius: $BlockBorderRadius