@mixin Message($backgroundColor, $color)
    padding: $fs-4
    margin: auto
        top: $fs-4
    
    width: 96%
    max-width: 800px

    color: $color

    background-color: rgba($backgroundColor,0.8)
    border: 1px solid $backgroundColor
        radius: 8px

    .Message-Text
        margin-bottom: 0

.Message-success
    @include Message(#77d661, #000)

.Message-warning
    @include Message(#ebc974, #000)

.Message-info
    @include Message(#74c7eb, #000)

.Message-danger
    @include Message(#eb7474, #000)