.GoToTop
    position: fixed
    bottom: 75px
    right: 20px

    display: flex
    justify-content: center
    align-items: center

    width: $fs-2
    height: $fs-2

    color: $inactiveColor
    background-color: $activeColor
    border: 1px solid $inactiveColor
        radius: 50%

    opacity: 0
    transition: opacity 0.3s ease

    &:hover
        color: $activeColor
        background-color: $inactiveColor
        border-color: $activeColor

    .GoToTop-Icon
        font-size: $fs-3

.GoToTop-Visible
    opacity: 1


@media (min-width: 1030px)
    .GoToTop
        bottom: 110px